import { PDF_MANUAL_EN_URL, PDF_MANUAL_ES_URL, TABLE_SIZE_PRESETS } from '@/app/constants'
import { Help } from '@/components/Help'
import { useAnalytics } from '@/features/analytics'
import { IssueStatus } from '@/features/issues'
import { StakeHolder } from '@/features/stakeHolders'
import { BulkActionsMenu, TaskStatus, useTaskBulkSelection } from '@/features/tasks'
import { ValidationRequestStatus } from '@/features/validationRequests'
import i18n from '@/i18n'
import { Badge, Button, COLOR, SIZE, Table } from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import { ReactNode, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TaskRow } from './TaskRow'

export enum TaskTableMode {
  EDIT,
  REVIEW,
  ISSUE_HANDLE,
}

export interface ITaskTableItem {
  status: TaskStatus
  dueDate: string
  dueDateRaw: string
  creationDate: string
  creationDateRaw: string
  validation?: {
    status: ValidationRequestStatus
    validator: string | undefined
    isValidatedByMe?: boolean
  }
  associatedStakeHolders?: StakeHolder[]
  issues?: {
    id: string
    status: IssueStatus
  }[]
  id: string
  name: string
  version: number
  assignedTo: string
}

interface TaskTableProps {
  tasks: ITaskTableItem[]
  isLoading: boolean
  mode?: TaskTableMode

  onRowClick: (taskId: string) => void

  leftMenu?: ReactNode

  issuesSummary?: {
    open: number
    acknowledged: number
    resolved: number
  }

  /**
   * Bulk actions
   */
  bulkActionsEnabled?: boolean
  bulkCloseEnabled?: boolean
  onCompleteBulk?: (taskIds: string[]) => void
  onValidationRequestBulk?: (taskIds: string[]) => void
  onReviewTaskBulk?: (taskIds: string[]) => void
  onCloseBulk?: (taskIds: string[]) => void

  /**
   * Pagination
   */
  onPageChange: (page: { pageIndex: number; pageSize: number }) => void
  totalCount: number
  pagination: { pageIndex: number; pageSize: number }
}

export const TaskTable = ({
  tasks,
  isLoading,
  mode = TaskTableMode.EDIT,
  onRowClick,
  leftMenu,
  bulkActionsEnabled,
  bulkCloseEnabled,
  onCompleteBulk,
  onValidationRequestBulk,
  onReviewTaskBulk,
  onCloseBulk,
  onPageChange,
  totalCount,
  pagination,
  issuesSummary,
}: TaskTableProps) => {
  const { t } = useTranslation('nsTasksListPage')
  const analytics = useAnalytics()

  const taskSelectionData = useMemo(
    () =>
      tasks.map(task => ({
        id: task.id,
        isPendingToValidate:
          !!task.validation && task.validation.status === ValidationRequestStatus.PENDING,
        isPendingToValidateByMe:
          !!task.validation &&
          task.validation.status === ValidationRequestStatus.PENDING &&
          !!task.validation.isValidatedByMe,
        isPending: task.status === TaskStatus.OPEN,
      })),
    [tasks]
  )

  const {
    allTaskPageSelected,
    selectedTaskIds,
    selectedTaskIdsToComplete,
    selectedTaskIdsToValidate,
    selectedTaskIdsToClose,
    disableToggle,
    toggleAllTasksInPage,
    toggleSelectedTask,
  } = useTaskBulkSelection({ tasks: taskSelectionData })

  const language = i18n.language

  const userManualUrl = useMemo(() => {
    if (language === 'es') {
      return PDF_MANUAL_ES_URL
    }
    return PDF_MANUAL_EN_URL
  }, [language])

  const taskRows = useMemo(
    () =>
      tasks.map(item =>
        TaskRow({
          item,
          mode,
          bulkActionsEnabled,
          selectedTaskIds,
          toggleSelectedTask,
          t,
        })
      ),
    [tasks, mode, bulkActionsEnabled, selectedTaskIds, toggleSelectedTask, t]
  )

  const isCompleteBulkActionEnabled =
    mode === TaskTableMode.EDIT && selectedTaskIdsToComplete.length > 0

  const isReviewBulkActionEnabled =
    (mode === TaskTableMode.EDIT || mode === TaskTableMode.REVIEW) &&
    selectedTaskIdsToValidate.length > 0

  const isBulkCloseEnabled = bulkCloseEnabled && selectedTaskIdsToClose.length > 0

  const isIssueHandleMode = mode === TaskTableMode.ISSUE_HANDLE

  const columns = useMemo(
    () => [
      {
        Header: t(`table.name`).toString(),
        accessor: 'name',
      },
      {
        Header: t(`table.version`).toString(),
        accessor: 'version',
      },
      {
        Header: t(`table.details`).toString(),
        accessor: 'details',
        disableGlobalFilter: true,
      },
      ...(isIssueHandleMode
        ? [
            {
              Header: t(`table.issues`).toString(),
              accessor: 'issues',
              disableGlobalFilter: true,
            },
          ]
        : []),
      {
        Header: t(`table.status`).toString(),
        accessor: 'status',
      },
      {
        accessor: 'rawStatus',
        hidden: true,
      },
      {
        Header: t(`table.creationDate`).toString(),
        accessor: 'creationDate',
        disableGlobalFilter: true,
      },
      {
        Header: t(`table.dueDate`).toString(),
        accessor: 'dueDate',
        disableGlobalFilter: true,
      },
      {
        Header: t('table.actions').toString(),
        accessor: 'actions',
        disableGlobalFilter: true,
      },
    ],
    [t, isIssueHandleMode]
  )

  return (
    <>
      <div
        className={classNames('ml-3 flex items-center h-9', {
          'justify-between': !!issuesSummary || bulkActionsEnabled,
          'justify-end': !issuesSummary || !bulkActionsEnabled,
        })}
      >
        {bulkActionsEnabled && (
          <BulkActionsMenu
            checkAllId='checkbox-task-all'
            selectAllText={t('bulk.selectAll')}
            disabled={disableToggle}
            toggleAllChecked={toggleAllTasksInPage}
            allChecked={allTaskPageSelected}
            someChecked={selectedTaskIds.length > 0}
            completeEnabled={isCompleteBulkActionEnabled}
            completeTooltipText={t('bulk.complete')}
            onComplete={() => onCompleteBulk?.(selectedTaskIdsToComplete)}
            validationRequestTooltipText={t('bulk.validationRequest')}
            onValidationRequest={() => onValidationRequestBulk?.(selectedTaskIdsToComplete)}
            validationReviewEnabled={isReviewBulkActionEnabled}
            validationReviewTooltipText={t('bulk.reviewValidation')}
            onValidationReview={() => onReviewTaskBulk?.(selectedTaskIdsToValidate)}
            closeEnabled={isBulkCloseEnabled}
            closeTooltipText={t('bulk.close')}
            onClose={() => {
              onCloseBulk?.(selectedTaskIdsToClose)
              toggleAllTasksInPage(false)
            }}
          />
        )}
        {issuesSummary && (
          <div className='flex gap-2'>
            <Badge color={COLOR.RED} variant='round'>
              {issuesSummary.open}
            </Badge>
            <Badge color={COLOR.YELLOW} variant='round'>
              {issuesSummary.acknowledged}
            </Badge>
            <Badge color={COLOR.GREEN} variant='round'>
              {issuesSummary.resolved}
            </Badge>
          </div>
        )}
        <div>{leftMenu}</div>
      </div>

      <Table
        manualPagination
        onPageChange={page =>
          onPageChange({ pageIndex: page.pageIndex, pageSize: pagination.pageSize })
        }
        onPageSizeChange={newPageSize =>
          onPageChange({ pageIndex: pagination.pageIndex, pageSize: newPageSize })
        }
        totalCount={totalCount}
        pageSize={pagination.pageSize}
        defaultPageIndex={pagination.pageIndex}
        isLoading={isLoading}
        className='mt-5'
        columns={columns}
        data={taskRows}
        onRowClick={row => {
          onRowClick(row.original.id)
        }}
        previousText={t('pagination.previous')}
        nextText={t('pagination.next')}
        renderInfoText={(from, to, total) => (
          <Trans
            t={t}
            i18nKey='pagination.show'
            components={{ b: <span className='font-semibold' /> }}
            values={{ from, to, total }}
          />
        )}
        sizePresetsText={t('pagination.items')}
        showSizePresets
        sizePresets={TABLE_SIZE_PRESETS}
        noDataText={
          <tr>
            <td colSpan={columns.length} className='text-center p-8 space-y-6'>
              <p className='text-4xl leading-10 font-extrabold text-center whitespace-pre-wrap'>
                {t('table.noData')}
              </p>
              <Button
                color='secondary'
                size={SIZE.EXTRA_LARGE}
                className='m-auto'
                onClick={() => {
                  analytics.track('ACTION_CLICK_HELP', {
                    Source: 'My tasks no results section',
                  })
                  window.open(userManualUrl, '_blank')
                }}
              >
                {t('table.needHelp')}
              </Button>
            </td>
          </tr>
        }
      />
      <Help analyticsProperties={{ Source: 'Tasks section' }} className='mt-2' />
    </>
  )
}
