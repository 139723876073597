import { TaskDetails, TaskIssuesBadges, TaskStatus } from '@/features/tasks'
import { ITaskTableItem, TaskTableMode } from './TaskTable'

import {
  Badge,
  Button,
  COLOR,
  CheckboxField,
} from '@blockchain-traceability-sl/tailwind-components'

interface TaskRowProps {
  item: ITaskTableItem
  mode: TaskTableMode
  bulkActionsEnabled?: boolean
  selectedTaskIds: string[]
  toggleSelectedTask: (taskId: string, isSelected: boolean) => void
  t: (key: string, options?: Record<string, string | number>) => string
}

// This is a higher-order function that creates a task row object
// We avoid using hooks inside this function
export const TaskRow = ({
  item,
  mode,
  bulkActionsEnabled,
  selectedTaskIds,
  toggleSelectedTask,
  t,
}: TaskRowProps) => {
  const { assignedTo, validation, name, associatedStakeHolders, ...task } = item
  const actionTextKey =
    mode === TaskTableMode.REVIEW ? 'table.action.review' : 'table.action.manage'

  // Return the row data without any hooks
  return {
    ...task,
    name: bulkActionsEnabled ? (
      <div className='flex'>
        <CheckboxField
          id={`checkbox-task-${task.id}`}
          name={`checkbox-task-${task.id}`}
          checked={selectedTaskIds.includes(task.id)}
          onChange={event => {
            toggleSelectedTask(task.id, event.target.checked)
          }}
          onClick={event => {
            event.stopPropagation()
          }}
        />
        {name}
      </div>
    ) : (
      name
    ),
    version: t('number', { value: task.version }),
    details: (
      <TaskDetails
        assignedTo={assignedTo}
        validation={validation}
        associatedStakeHolders={associatedStakeHolders}
      />
    ),
    // For issues, we'll use the ones passed in the item instead of fetching them
    issues: item.issues && <TaskIssuesBadges issues={item.issues} />,
    status: (
      <Badge color={task.status === TaskStatus.OPEN ? COLOR.YELLOW : COLOR.GRAY}>
        {t(`table.statusTypes.${task.status}`)}
      </Badge>
    ),
    rawStatus: t(`table.statusTypes.${task.status}`),
    actions: <Button color='link'>{t(actionTextKey)}</Button>,
  }
}
