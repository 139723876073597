import { Audit, useAuditsTaskById } from '@/features/audits'
import { useCompanyUser, useMyProfile } from '@/features/auth'
import { useAutomations } from '@/features/automations'
import { useUsersWithOwner } from '@/features/users'
import {
  ValidationRequest,
  ValidationRequestReviewer,
  ValidationRequestStatus,
  useGetLastValidator,
} from '@/features/validationRequests'
import {
  COLOR,
  CollapsableText,
  Feeds,
  Loader,
  Modal,
  NEW_COLOR,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import { FeedProps } from '@blockchain-traceability-sl/tailwind-components/dist/components/molecules/Feeds/src/Feed'
import { BadgeCheckIcon, CheckIcon, ClipboardCheckIcon, MailIcon } from '@heroicons/react/outline'
import { FlagIcon, FolderOpenIcon, PencilIcon, UserAddIcon, XIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTaskById } from '../../hooks'
import { Task, TaskReportSent } from '../../interfaces'
import { getAssignedCreatorTaskName } from '../../utils'
import { ACTIVITY_TYPE } from './entity'
import { formatAuditToActivities } from './utils'
import { sortTaskActivities } from './utils/sort-task-activities'

export interface ModalTaskActivityProps {
  show: boolean
  taskId?: string | null
  toggle: () => void
}

export const ModalTaskActivity = ({ show, toggle, taskId }: ModalTaskActivityProps) => {
  const { t } = useTranslation('nsModalTaskActivity')
  const { task } = useTaskById(taskId || '', {
    enabled: !!taskId,
  })
  const { getLastValidatorName, getLastValidator } = useGetLastValidator()

  const { audits, isLoading } = useAuditsTaskById(taskId || '', {
    enabled: !!taskId,
  })
  const { profile } = useMyProfile()

  const [hideDeletedUsers, setHideDeletedUsers] = useState(false)

  const { users } = useUsersWithOwner()
  const company = useCompanyUser()
  const { automations } = useAutomations({
    enabled: !!taskId && task?.automated,
  })

  useEffect(() => {
    if (profile) {
      const hideDeletedUsers = profile.addons.custom?.taskActivityDefaultCompanyOwner
      if (hideDeletedUsers) {
        setHideDeletedUsers(true)
      }
    }
  }, [profile])

  const ACTIVITY_ELEMENTS = useMemo(
    () => ({
      [ACTIVITY_TYPE.VALIDATION_CREATED]: {
        icon: BadgeCheckIcon,
        color: COLOR.BLUE,
        text: (audit?: Audit<ValidationRequest>) => (
          <Trans
            t={t}
            i18nKey='activity.sentValidation'
            components={{ b: <b /> }}
            values={{
              userName: getAssignedCreatorTaskName(audit?.data?.requester.id || '', {
                company,
                users,
                automations,
                defaultValue: task?.automated ? t('automationDeleted') : t('userDeleted'),
              }),
              receiverName: audit?.data.reviewers.length
                ? audit.data.reviewers
                    .map(({ id }) =>
                      getAssignedCreatorTaskName(id, {
                        company,
                        users,
                        defaultValue: t('userDeleted'),
                      })
                    )
                    .join(', ')
                : getAssignedCreatorTaskName('', {
                    company,
                    users,
                    defaultValue: t('userDeleted'),
                  }),
            }}
          />
        ),
      },
      [ACTIVITY_TYPE.TASK_CREATION]: {
        icon: PencilIcon,
        color: COLOR.BLUE,
        text: (audit?: Audit<Task>) => (
          <Trans
            t={t}
            i18nKey='activity.creation'
            components={{ b: <b /> }}
            values={{
              name: getAssignedCreatorTaskName(
                /**
                 * If the task is not automated, the createdBy field is the user id
                 * If the task is automated, the createdBy field is the automation id
                 * If task is automated by compliance, we use automationTriggeredBy user id if exists
                 */
                audit?.data?.automationTriggeredBy?.userId || audit?.data?.createdBy || '',
                {
                  company,
                  users,
                  automations,
                  defaultValue: task?.automated ? t('automationDeleted') : t('userDeleted'),
                }
              ),
            }}
          />
        ),
      },
      [ACTIVITY_TYPE.TASK_CLOSE]: {
        icon: FlagIcon,
        color: NEW_COLOR.TEAL,
        text: (audit?: Audit<Task>) => (
          <Trans
            t={t}
            i18nKey='activity.close'
            components={{ b: <b /> }}
            values={{
              name: getAssignedCreatorTaskName(audit?.data?.updatedBy || '', {
                company,
                users,
                defaultValue: t('userDeleted'),
              }),
            }}
          />
        ),
      },
      [ACTIVITY_TYPE.TASK_OPEN]: {
        icon: FolderOpenIcon,
        color: NEW_COLOR.SKY,
        text: (audit?: Audit<Task>) => (
          <Trans
            t={t}
            i18nKey='activity.open'
            components={{ b: <b /> }}
            values={{
              name: getAssignedCreatorTaskName(
                audit?.data?.updatedBy || audit?.data?.createdBy || '',
                {
                  company,
                  users,
                  defaultValue: t('userDeleted'),
                }
              ),
            }}
          />
        ),
      },
      [ACTIVITY_TYPE.TASK_ASSIGN]: {
        icon: UserAddIcon,
        color: COLOR.YELLOW,
        text: (audit?: Audit<Task>, previousAudit?: Audit<Task>) => (
          <Trans
            t={t}
            i18nKey='activity.assignation'
            components={{ b: <b /> }}
            values={{
              name: getAssignedCreatorTaskName(
                audit?.data?.updatedBy ||
                  previousAudit?.data.assignedTo ||
                  audit?.data?.createdBy ||
                  '',
                {
                  company,
                  users,
                  automations,
                  defaultValue: task?.automated ? t('automationDeleted') : t('userDeleted'),
                }
              ),
              name2: getAssignedCreatorTaskName(audit?.data?.assignedTo || '', {
                company,
                users,
                defaultValue: hideDeletedUsers ? profile?.owner?.name : t('userDeleted'),
              }),
            }}
          />
        ),
      },
      [ACTIVITY_TYPE.TASK_UPDATED]: {
        icon: ClipboardCheckIcon,
        color: COLOR.INDIGO,
        text: (audit?: Audit<Task>) => (
          <Trans
            t={t}
            i18nKey='activity.complete'
            components={{ b: <b /> }}
            values={{
              name: getAssignedCreatorTaskName(
                audit?.data?.updatedBy || audit?.data?.assignedTo || audit?.data?.createdBy || '',
                {
                  company,
                  users,
                  defaultValue: hideDeletedUsers ? profile?.owner?.name : t('userDeleted'),
                }
              ),
            }}
          />
        ),
      },
      [ACTIVITY_TYPE.VALIDATION_ACCEPT]: {
        icon: CheckIcon,
        color: COLOR.GREEN,
        text: (audit?: Audit<ValidationRequest>) => (
          <Trans
            t={t}
            i18nKey='activity.approve'
            components={{ b: <b /> }}
            values={{
              name: getLastValidatorName({
                status: audit?.data?.status as ValidationRequestStatus,
                reviewers: audit?.data?.reviewers as ValidationRequestReviewer[],
              }),
            }}
          />
        ),
      },
      [ACTIVITY_TYPE.VALIDATION_REJECT]: {
        icon: XIcon,
        color: COLOR.RED,
        text: (audit?: Audit<ValidationRequest>) => {
          const lastValidator = getLastValidator({
            status: audit?.data?.status as ValidationRequestStatus,
            reviewers: audit?.data?.reviewers as ValidationRequestReviewer[],
          })
          return (
            <>
              <Trans
                t={t}
                i18nKey='activity.reject'
                components={{ b: <b /> }}
                values={{
                  name: lastValidator?.name,
                }}
              />
              <br />

              {lastValidator?.message && (
                <CollapsableText
                  seeMoreText={t('activity.seeMore')}
                  seeLessText={t('activity.seeLess')}
                >
                  <Trans
                    t={t}
                    i18nKey='activity.rejectWithMessage'
                    components={{ b: <b /> }}
                    values={{
                      message: lastValidator.message,
                    }}
                  />
                </CollapsableText>
              )}
            </>
          )
        },
      },
      [ACTIVITY_TYPE.TASK_REPORT_SENT]: {
        icon: MailIcon,
        color: COLOR.BLUE,
        text: (audit?: Audit<TaskReportSent>) => (
          <Trans
            t={t}
            i18nKey={'activity.sendReport'}
            components={{ b: <b /> }}
            values={{
              name: getAssignedCreatorTaskName(audit?.data?.createdBy || '', {
                company,
                users,
                defaultValue: hideDeletedUsers ? profile?.owner?.name : t('userDeleted'),
              }),
              targets: audit?.data.to.join(', '),
            }}
          />
        ),
      },
    }),
    [
      t,
      company,
      users,
      automations,
      task?.automated,
      hideDeletedUsers,
      profile?.owner?.name,
      getLastValidatorName,
      getLastValidator,
    ]
  )

  const activities = useMemo(
    () =>
      sortTaskActivities(
        audits
          // Format audit to activities
          .flatMap(formatAuditToActivities)
      ),
    [audits]
  )

  const feeds = useMemo(
    (): FeedProps[] =>
      activities
        // Format activities to feeds
        .map(({ type, audit, previousAudit }) => {
          let title = null

          switch (type) {
            case ACTIVITY_TYPE.VALIDATION_CREATED:
            case ACTIVITY_TYPE.VALIDATION_ACCEPT:
            case ACTIVITY_TYPE.VALIDATION_REJECT:
              title = ACTIVITY_ELEMENTS[type].text(audit as Audit<ValidationRequest>)
              break
            case ACTIVITY_TYPE.TASK_CREATION:
            case ACTIVITY_TYPE.TASK_ASSIGN:
            case ACTIVITY_TYPE.TASK_CLOSE:
            case ACTIVITY_TYPE.TASK_OPEN:
            case ACTIVITY_TYPE.TASK_UPDATED:
              title = ACTIVITY_ELEMENTS[type].text(
                audit as Audit<Task>,
                previousAudit as Audit<Task> | undefined
              )
              break
            case ACTIVITY_TYPE.TASK_REPORT_SENT:
              title = ACTIVITY_ELEMENTS[type].text(audit as Audit<TaskReportSent>)
              break
          }

          return {
            title: <div className='text-left'>{title}</div>,
            time: dayjs(audit.createdAt).format('DD/MM/YYYY, HH:mm'),
            color: ACTIVITY_ELEMENTS[type].color,
            iconColor: ACTIVITY_ELEMENTS[type].color,
            icon: ACTIVITY_ELEMENTS[type].icon,
            isIconLight: true,
            content: null,
          }
        }),
    [ACTIVITY_ELEMENTS, activities]
  )

  return (
    <Modal size={SIZE.LARGE} show={show} toggle={toggle} dismissButton>
      <Modal.Title>{t('title')}</Modal.Title>
      <Modal.Body>
        <div>{isLoading ? <Loader center='full' /> : <Feeds feeds={feeds} />}</div>
      </Modal.Body>
    </Modal>
  )
}
